
export const optionsPrefix = [
  { value: 'นาย', label: 'นาย' },
  { value: 'นาง', label: 'นาง' },
  { value: 'นางสาว', label: 'นางสาว' },
  { value: 'อื่นๆ', label: 'อื่นๆ' }
]

export const optionsCareer = [
  { value: 'พนักงานราชการ', label: 'พนักงานราชการ' },
  { value: 'พนักงานเงินเดือน', label: 'พนักงานเงินเดือน' },
  { value: 'พนักงานชั่วคราว/พนักงานอิสระ', label: 'พนักงานชั่วคราว/พนักงานอิสระ' },
  { value: 'เจ้าของกิจการ', label: 'เจ้าของกิจการ' }
]

export const optionsPeriod = [
  { label: '6', value: 6 },
  { label: '10', value: 10 },
  { label: '12 *นักศึกษาเท่านั้น', value: 12 }
]

export const optionsPhoneSystem = [
  { value: 'เติมเงิน', label: 'เติมเงิน' },
  { value: 'รายเดือน', label: 'รายเดือน' },
  { value: 'รายปี', label: 'รายปี' }
]

// export const optionsKnownUfriendFrom = [
//   { value: 'TIKTOK', label: 'Tiktok' },
//   { value: 'FACEBOOK', label: 'Facebook' },
//   { value: 'SEARCH_GOOGLE', label: 'Search เจอจาก Google' },
//   { value: 'STOREFRONT_SIGN', label: 'ป้ายหน้าร้าน' },
//   { value: 'LINE', label: 'Line' },
//   { value: 'FRIEND_REC', label: 'เพื่อนแนะนำ' },
//   { value: 'CAR_ADVERTISE', label: 'ป้ายโฆษณาติดรถ' },
//   { value: 'INSTAGRAM', label: 'Instagram' },
// ]

export const optionRelationship = [
  { value: 'พ่อแม่', label: 'พ่อแม่' },
  { value: 'พี่น้อง', label: 'พี่น้อง' },
  { value: 'สามี/ภรรยา/แฟน', label: 'สามี/ภรรยา/แฟน' },
  { value: 'ลูก', label: 'ลูก' },
  { value: 'ญาติ', label: 'ญาติ' },
  { value: 'เพื่อน/หัวหน้างาน', label: 'เพื่อน/หัวหน้างาน' }
]

// export const optionRegisterSalary = [
//   {
//     "id": 1,
//     "value": "7000",
//     "label": "6,000 - 7,999 บาท"
//   },
//   {
//     "id": 2,
//     "value": "9000",
//     "label": "8,000 - 9,999 บาท"
//   },
//   {
//     "id": 3,
//     "value": "11500",
//     "label": "10,000 - 12,999 บาท"
//   },
//   {
//     "id": 4,
//     "value": "14500",
//     "label": "13,000 - 15,999 บาท"
//   },
//   {
//     "id": 5,
//     "value": "18000",
//     "label": "16,000 - 19,999 บาท"
//   },
//   {
//     "id": 6,
//     "value": "22500",
//     "label": "20,000 - 24,999 บาท"
//   },
//   {
//     "id": 7,
//     "value": "27500",
//     "label": "25,000 - 29,999 บาท"
//   },
//   {
//     "id": 8,
//     "value": "35000",
//     "label": "30,000 - 39,999 บาท"
//   },
//   {
//     "id": 9,
//     "value": "50000",
//     "label": "40,000 บาทขึ้นไป"
//   }
// ]

export const optionRegisterCollge = [
  {
    "id": 1,
    "value": "1",
    "label": "1"
  },
  {
    "id": 2,
    "value": "2",
    "label": "2"
  },
  {
    "id": 3,
    "value": "3",
    "label": "3"
  },
  {
    "id": 4,
    "value": "4",
    "label": "4"
  },
  {
    "id": 5,
    "value": "5",
    "label": "5"
  },
  {
    "id": 6,
    "value": "6",
    "label": "6"
  },
]

export const optionsExperience = [
  { value: 'น้อยกว่า 3 เดือน', label: 'น้อยกว่า 3 เดือน' },
  { value: '3-6 เดือน', label: '3-6 เดือน' },
  { value: '6 เดือน - 2 ปี', label: '6 เดือน - 2 ปี' },
  { value: '2 ปีขึ้นไป', label: '2 ปีขึ้นไป' }
]

export const optionsSalaryStudent = [
  { value: 'พ่อแม่ หรือผู้ปกครอง', label: 'พ่อแม่ หรือผู้ปกครอง' },
  { value: 'งานประจำ', label: 'งานประจำ' },
  { value: 'งานพาร์ทไทม์', label: 'งานพาร์ทไทม์' },
  { value: 'เงินกู้ยืมจาก กยศ', label: 'เงินกู้ยืมจาก กยศ' }
]

export const optionDegree = [
  { value: "ปวส", label: "ปวส" }, { value: "ป.ตรี", label: "ป.ตรี" }
]






